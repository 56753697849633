// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-code-of-practise-js": () => import("./../../../src/pages/code-of-practise.js" /* webpackChunkName: "component---src-pages-code-of-practise-js" */),
  "component---src-pages-course-js": () => import("./../../../src/pages/course.js" /* webpackChunkName: "component---src-pages-course-js" */),
  "component---src-pages-enrol-js": () => import("./../../../src/pages/enrol.js" /* webpackChunkName: "component---src-pages-enrol-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-level-5-js": () => import("./../../../src/pages/level-5.js" /* webpackChunkName: "component---src-pages-level-5-js" */),
  "component---src-pages-level-6-js": () => import("./../../../src/pages/level-6.js" /* webpackChunkName: "component---src-pages-level-6-js" */),
  "component---src-pages-page-2-tsx": () => import("./../../../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */),
  "component---src-pages-pathways-js": () => import("./../../../src/pages/pathways.js" /* webpackChunkName: "component---src-pages-pathways-js" */),
  "component---src-pages-study-early-childhood-education-js": () => import("./../../../src/pages/study-early-childhood-education.js" /* webpackChunkName: "component---src-pages-study-early-childhood-education-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */)
}

